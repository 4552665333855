
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { removeLinkActive } from "@/core/helpers/dom";
import Cards from "@/components/base/common/Cards.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import ClaimCard from "@/views/claim/ClaimCard.vue";
import ActiveClaims from "@/views/policy/claim/ActiveClaims.vue";
import CloseCases from "@/views/policy/case/CloseCases.vue";

export default defineComponent({
  name: "Claims",
  components: {CloseCases, ActiveClaims, ClaimCard, QuickAction, Cards},
  props: {
    id: {type: String}
  },
  setup(props) {
    onUpdated(() => {
      removeLinkActive()
    })
    onMounted(() => {
      removeLinkActive()
    })
    const policy = computed(() => store.state.PolicyModule.policy);
    const filterObject = ref({policyId: props.id})
    const claimPage = computed(() => store.state.ClaimModule.policyClaimPage);
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner','policy']),
      claimPage,
      policy,
      ...LoadPanel(),
    }
  }
})
