
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import ClaimCard from "@/views/claim/ClaimCard.vue";
import Cards from "@/components/base/common/Cards.vue";

export default defineComponent({
  name: "ActiveClaims",
  components: {Cards, ClaimCard},
  props: {
    policyId: {}
  },
  setup(props) {
    const filterObject = ref({policyId: props.policyId, status: 'ACTIVE'})
    const page = computed(() => store.state.PolicyModule.activeClaims)
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_ACTIVE_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner']),
      page
    }
  }
})
